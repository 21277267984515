import config from 'later/config/environment';

/**
 * Checks if the current environment is production
 */
const isProduction = (): boolean => document.location.hostname === 'app.later.com';

/**
 * Checks if the current environment lazy loads translations
 */
const isLazyLoad = (): boolean => config.lazyLoadTranslations;

export { isProduction, isLazyLoad };
