import Service, { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import graph from 'fbgraph';
import RSVP from 'rsvp';

import config from 'later/config/environment';
import createIgCommentFromGraph from 'later/utils/formatters/ig-comment-from-graph';
import createIgPostFromGraph from 'later/utils/formatters/ig-post-from-graph';
import graphCall from 'later/utils/graph-call';
import createIgUserFromGraph from 'shared/utils/formatters/ig-user-from-graph';

import type { Hashtag } from 'collect/types/hashtag-search';
import type IgComment from 'later/models/ig-comment';
import type IgPost from 'later/models/ig-post';
import type SocialProfileModel from 'later/models/social-profile';
import type AuthService from 'later/services/auth';
import type IgUser from 'shared/models/ig-user';
import type { EmptyObject, Maybe } from 'shared/types';
import type {
  InstagramPagination,
  InstagramUserInfo,
  InstagramCommentReply,
  InstagramGraphComment,
  InstagramGraphMediaResponse,
  RecentInstagramMediaGraph
} from 'shared/types/instagram';
import type { JsonValue } from 'type-fest';
import LaterConfigService from './later-config';

export interface FetchMediaParams {
  count?: number;
  'jolteon-refresh-cache'?: boolean;
}

export interface FetchHashtagParams {
  after?: string;
  limit?: number;
}

interface InstagramGraphError {
  code: number;
}

export interface InstagramPosts {
  pagination: InstagramPagination;
  posts: IgPost[];
}

interface InstagramGraphError {
  code: number;
}

export default class InstagramGraphService extends Service {
  @service declare auth: AuthService;
  @service declare laterConfig: LaterConfigService;

  /**
   * Code from possible error response following request to Graph API
   */
  errorCode?: number;

  constructor(args: EmptyObject) {
    super(args);
    graph.setVersion(this.laterConfig.facebookGraphVersion);
  }

  /**
   * Sets the FbGraph User Access token that will be used
   * in all subsequent requests to the Graph API
   */
  setAccessToken(userAccessToken: string): void {
    graph.setAccessToken(userAccessToken);
  }

  /**
   * Gets the FbGraph Access token that will be used
   * in all subsequent requests to the Graph API
   */
  getAccessToken(): Maybe<string> {
    return graph.getAccessToken();
  }

  /**
   * Fetches Recent Media (Posts) for specified User via the Instagram API.
   */
  fetchRecentMedia(
    socialProfile: SocialProfileModel,
    params: FetchMediaParams,
    token: Maybe<string>
  ): Promise<RecentInstagramMediaGraph> {
    // pass it in as part of the url
    const fields = [
      'caption',
      'comments_count',
      'ig_id',
      'like_count',
      'media_product_type',
      'media_type',
      'media_url',
      'permalink',
      'thumbnail_url',
      'timestamp'
    ].join(',');
    const limit = 30;
    const url = socialProfile.get('businessAccountId') + `/media?fields=${fields}&limit=${limit}`;
    return new RSVP.Promise((resolve, reject) => {
      graphCall(url, params, { token, backupToken: socialProfile.get('businessAccountToken') })
        .then((response) => {
          if (isEmpty(response.data)) {
            // Note: If no posts are found, we are forcing an error to fall back to old IG API
            const errorMessage = 'Error: Graph API response contains no media.';
            reject(errorMessage);
          } else {
            const posts = response.data.map((rawPost: InstagramGraphMediaResponse) =>
              createIgPostFromGraph(rawPost, socialProfile)
            );
            resolve({
              pagination: response.paging,
              posts
            });
          }
        })
        .catch((error) => reject(error));
    });
  }

  /**
   * Fetches specified Instagram Media (Post) via the Instagram API.
   *
   * @param socialProfile The socialProfile making the request
   * @param mediaId instagram media id to fetch
   * @param params Endpoint specific parameters
   *
   * @returns Formatted Instagram Post
   */
  fetchMedia(
    socialProfile: SocialProfileModel,
    mediaId: string,
    params: FetchMediaParams = {},
    token: string
  ): Promise<IgPost> {
    return new RSVP.Promise((resolve, reject) => {
      // pass it in as part of the url
      const fields = [
        'caption',
        'ig_id',
        'comments_count',
        'like_count',
        'media_url',
        'thumbnail_url',
        'media_type',
        'timestamp',
        'permalink'
      ].join(',');

      const tokenParams = token ? `&access_token=${token}` : '';

      graph.get(
        `${mediaId}?fields=${fields}${tokenParams}`,
        params,
        (error: InstagramGraphError, res: InstagramGraphMediaResponse) => {
          if (error) {
            reject(error);
          } else {
            const post = createIgPostFromGraph(res, socialProfile);
            resolve(post);
          }
        }
      );
    });
  }

  fetchSelf(socialProfile: SocialProfileModel, token: string): Promise<IgUser> {
    const businessAccountId = socialProfile.get('businessAccountId');
    const fields = [
      'id',
      'name',
      'media_count',
      'profile_picture_url',
      'username',
      'followers_count',
      'follows_count',
      'website'
    ].join(',');
    return new RSVP.Promise((resolve, reject) => {
      graphCall(
        `/${businessAccountId}`,
        { fields },
        {
          token,
          backupToken: socialProfile.businessAccountToken
        }
      )
        .then((response) => {
          const igUser = createIgUserFromGraph(response, socialProfile);
          resolve(igUser);
        })
        .catch((error) => reject(error));
    });
  }

  fetchHashtag(socialProfile: SocialProfileModel, name: string, token?: string): Promise<Hashtag> {
    return new RSVP.Promise((resolve, reject) => {
      const fields = ['id', 'name'].join(',');
      const tokenParams = token ? `&access_token=${token}` : '';
      const url = `ig_hashtag_search?fields=${fields}&user_id=${socialProfile.get(
        'businessAccountId'
      )}&q=${name}${tokenParams}`;
      graph.get(url, (err: InstagramGraphError, res: { data: Hashtag[] }) => {
        if (err) {
          this.set('errorCode', err.code);
          reject(err);
        } else {
          resolve(res.data[0]);
        }
      });
    });
  }

  fetchHashtagById(hashtagId: string, params: FetchHashtagParams = {}): Promise<Hashtag> {
    return new RSVP.Promise((resolve, reject) => {
      const fields = ['id', 'name'].join(',');
      const url = `${hashtagId}?fields=${fields}`;
      graph.get(url, params, (err: InstagramGraphError, res: Hashtag) => {
        if (err) {
          this.set('errorCode', err.code);
          reject(err);
        } else {
          resolve(res);
        }
      });
    });
  }

  fetchHashtagMedia(
    endpoint = 'recent_media',
    socialProfile: SocialProfileModel,
    hashtagId: string,
    params: FetchHashtagParams = { limit: 50 }
  ): Promise<InstagramPosts> {
    return new RSVP.Promise((resolve, reject) => {
      const fields = [
        'caption',
        'comments_count',
        'id',
        'like_count',
        'media_type',
        'media_url',
        'permalink',
        'children{media_type,media_url}'
      ].join(',');
      const url = `${hashtagId}/${endpoint}?user_id=${socialProfile.get('businessAccountId')}&fields=${fields}`;
      graph.get(
        url,
        params,
        (err: InstagramGraphError, res: { data: InstagramGraphMediaResponse[]; paging: InstagramPagination }) => {
          if (err) {
            this.set('errorCode', err.code);
            reject(err);
          } else {
            // Note: api does not return media_url for copyrighted content
            const posts = res.data
              .filter((m: InstagramGraphMediaResponse) => m.children || m.media_url)
              .map((m: InstagramGraphMediaResponse) => createIgPostFromGraph(m, socialProfile));
            resolve({
              pagination: res.paging,
              posts
            });
          }
        }
      );
    });
  }

  fetchRecentHashtag(
    socialProfile: SocialProfileModel,
    params: FetchHashtagParams = { limit: 50 }
  ): Promise<Hashtag[]> {
    return new RSVP.Promise((resolve, reject) => {
      const fields = ['id', 'name'].join(',');
      const url = `${socialProfile.businessAccountId}/recently_searched_hashtags?fields=${fields}`;
      graph.get(url, params, (err: InstagramGraphError, res: { data: Hashtag[] }) => {
        if (err) {
          this.set('errorCode', err.code);
          reject(err);
        } else {
          resolve(res.data);
        }
      });
    });
  }

  /**
   * Fetches a business user for the specified username via the Business Discovery API.
   */
  async fetchBusinessUser(socialProfile: SocialProfileModel, username?: string): Promise<InstagramUserInfo> {
    const { businessAccountToken, businessAccountId } = socialProfile;
    const token = businessAccountToken || this.auth.currentUserModel.facebookToken;
    const params = {};
    const fields = [
      'followers_count',
      'media_count',
      'biography',
      'name',
      'profile_picture_url',
      'website',
      'username'
    ];
    const url = `/${businessAccountId}?fields=business_discovery.username(${username}){${fields}}`;
    const response = await graphCall(url, params, { token, backupToken: businessAccountToken });
    const userInfo = { ...response.business_discovery };
    return userInfo;
  }

  /**
   * Fetches all Instagram Media (Posts) for the specified username via the Business Discovery API.
   */
  fetchRecentMediaByUsername(
    socialProfile: SocialProfileModel,
    username?: string,
    afterCursor = ''
  ): Promise<{ posts: Maybe<IgPost[]>; userInfo: InstagramUserInfo; pagination?: InstagramPagination }> {
    return new RSVP.Promise((resolve, reject) => {
      const { businessAccountToken, businessAccountId } = socialProfile;
      const token = businessAccountToken || this.auth.currentUserModel.facebookToken;
      const params = {};
      const limit = 30;
      const fields = [
        'followers_count',
        'media_count',
        'biography',
        'name',
        'profile_picture_url',
        'website',
        'username'
      ];
      const mediaFields = [
        'id',
        'caption',
        'media_type',
        'media_url',
        'permalink',
        'comments_count',
        'like_count',
        'children{media_type,media_url}',
        'username',
        'thumbnail_url'
      ].join(',');

      if (isEmpty(afterCursor)) {
        fields.push(`media.limit(${limit}){${mediaFields}}`);
        fields.join(',');
      } else {
        fields.push(`media.after(${afterCursor}).limit(${limit}){${mediaFields}}`);
        fields.join(',');
      }
      const url = `/${businessAccountId}?fields=business_discovery.username(${username}){${fields}}`;
      graphCall(url, params, { token, backupToken: businessAccountToken })
        .then((response) => {
          if (isEmpty(response.business_discovery.media)) {
            const userInfo = { ...response.business_discovery };
            const posts = undefined;
            resolve({
              posts,
              userInfo
            });
          } else {
            const posts = response.business_discovery.media.data.map((rawPost: InstagramGraphMediaResponse) =>
              createIgPostFromGraph(rawPost, socialProfile)
            );
            const userInfo = { ...response.business_discovery };
            delete userInfo.media;
            resolve({
              pagination: response.business_discovery.media.paging,
              posts,
              userInfo
            });
          }
        })
        .catch((error) => reject(error));
    });
  }

  fetchTaggedMedia(socialProfile: SocialProfileModel, params: FetchMediaParams): Promise<InstagramPosts> {
    return new RSVP.Promise((resolve, reject) => {
      const fields = [
        'caption',
        'comments_count',
        'id',
        'like_count',
        'media_type',
        'media_url',
        'permalink',
        'timestamp',
        'username',
        'children{media_type,media_url}'
      ].join(',');
      const url = `${socialProfile.businessAccountId}/tags?fields=${fields}`;
      graphCall(url, params, {
        backupToken: socialProfile.businessAccountToken
      })
        .then((response) => {
          // api does not return media_url for copyrighted content
          const posts = response.data
            .filter((post: { children?: JsonValue; media_url?: string }) => post.children || post.media_url)
            .map((post: InstagramGraphMediaResponse) => createIgPostFromGraph(post, socialProfile));
          resolve({
            pagination: response.paging,
            posts
          });
        })
        .catch((error) => {
          this.set('errorCode', error.code);
          reject(error);
        });
    });
  }

  /**
   * Fetches comments on a specified Instagram post.
   */
  fetchMediaComments(
    socialProfile: SocialProfileModel,
    media: IgPost,
    pagingInfo: Maybe<string>,
    token: string
  ): Promise<IgComment[]> {
    return new RSVP.Promise((resolve, reject) => {
      const commentFields = ['id', 'like_count', 'text', 'timestamp', 'username'];
      const fields = [commentFields.join(','), 'media', `replies{${commentFields.join(',')}}`].join(',');
      const limit = 50;
      const params = pagingInfo ? { after: pagingInfo } : {};

      graph.get(
        media.id + `/comments?fields=${fields}&limit=${limit}&access_token=${token}`,
        params,
        (err: InstagramGraphError, res: { data: InstagramGraphComment[]; paging: InstagramPagination }) => {
          if (err) {
            return reject(err);
          }

          const uniqueCommentIds = [...new Set(res.data.map((rawComment) => rawComment.id))];
          const comments: IgComment[] = [];

          for (const uniqueId of uniqueCommentIds) {
            const comment = res.data.find((responseObj) => responseObj.id === uniqueId);
            if (comment) {
              const igComment = createIgCommentFromGraph(comment, socialProfile, media.id);
              comments.push(igComment);
              igComment.replies.forEach((reply) => {
                comments.push(reply);
              });
            }
          }
          const hasPagingInfo = Boolean(res.paging && res.paging.cursors);
          media.set('pagingInfo', hasPagingInfo ? res.paging.cursors.after : undefined);

          return resolve(comments);
        }
      );
    });
  }

  /**
   * Posts a reply to a specified Instagram comment.
   */
  postReply(commentId: string, message: string, isReply = false): Promise<InstagramCommentReply> {
    const endpoint = isReply ? 'replies' : 'comments';
    return new RSVP.Promise((resolve, reject) => {
      const fields = [
        'id',
        'like_count',
        'text',
        'timestamp',
        'username',
        'media{id,caption,ig_id,comments_count,media_url,thumbnail_url,media_type,timestamp,permalink}'
      ].join(',');

      graph.post(
        `${commentId}/${endpoint}?fields=${fields}`,
        { message },
        (error: InstagramGraphError, response: InstagramCommentReply) => (error ? reject(error) : resolve(response))
      );
    });
  }

  /**
   * Deletes a specified Instagram comment.
   */
  deleteComment(commentId: string, params = {}): Promise<void> {
    return new RSVP.Promise((resolve, reject) => {
      graph.del(commentId, params, (err: InstagramGraphError) => (err ? reject(err) : resolve()));
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    'instagram-graph': InstagramGraphService;
  }
}
