/* eslint-disable import/order */
// Note: Import order may matter here: https://latermedia.slack.com/archives/CKNAZ2Z98/p1673047507069279
import Service, { inject as service } from '@ember/service';
import { capitalize } from '@ember/string';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import moment from 'moment';

import config from 'later/config/environment';
import { DATE_LENGTH, FALLBACK_LOCALE, LOCALE_CACHE_KEY } from 'later/utils/constants';
import { fetch } from 'later/utils/fetch';

import '@formatjs/intl-displaynames/polyfill';
import '@formatjs/intl-displaynames/locale-data/de';
import '@formatjs/intl-displaynames/locale-data/en';
import '@formatjs/intl-displaynames/locale-data/es';
import '@formatjs/intl-displaynames/locale-data/fr';
import '@formatjs/intl-listformat/locale-data/de';
import '@formatjs/intl-listformat/locale-data/en';
import '@formatjs/intl-listformat/locale-data/es';
import '@formatjs/intl-listformat/locale-data/fr';
import '@formatjs/intl-listformat/polyfill';
import { isLazyLoad } from 'later/utils/is-env';

export default class LocaleService extends Service {
  @service alerts;
  @service auth;
  @service cache;
  @service errors;
  @service intl;
  @service laterConfig;
  @service userConfig;

  @tracked primaryLocale = this.intl.primaryLocale;

  DATE_LENGTH = DATE_LENGTH;

  sunsettedLanguages = ['ja'];

  get enabledLanguageCodes() {
    const includedLocaleCodes = this.intl.locales;
    const enabledLanguageCodes = ['en-us', 'es', 'de', 'fr'];

    const availableLanguageCodes = enabledLanguageCodes.filter((enabledLanguageCode) =>
      includedLocaleCodes.includes(enabledLanguageCode)
    );

    return isLazyLoad() ? enabledLanguageCodes : availableLanguageCodes;
  }

  get cacheKey() {
    return this.auth.currentUserModel?.id ? `${LOCALE_CACHE_KEY}_${this.auth.currentUserModel?.id}` : undefined;
  }

  get browserLocale() {
    return navigator?.languages?.find((code) => {
      const lowerCaseCode = code.toLowerCase();
      if (this.enabledLanguageCodes.includes(lowerCaseCode)) {
        return true;
      }
      return false;
    });
  }

  get savedLocale() {
    return this.cacheKey ? this.cache.retrieve(this.cacheKey) : undefined;
  }

  get userLocale() {
    return this.auth.currentUserModel?.language;
  }

  get userLocaleCode() {
    const userLocaleCode = this.userLocale || this.savedLocale || this.browserLocale || FALLBACK_LOCALE;
    return userLocaleCode.toLowerCase();
  }

  get userLocaleName() {
    const languageNames = new Intl.DisplayNames(this.primaryLocale, { type: 'language', style: 'long' });
    return capitalize(languageNames.of(this.userLocaleCode));
  }

  get enabledLocales() {
    const languageNames = new Intl.DisplayNames(this.primaryLocale, { type: 'language', style: 'long' });
    return this.enabledLanguageCodes.map((locale) => ({ code: locale, name: capitalize(languageNames.of(locale)) }));
  }

  get assetHostPath() {
    return config.assetHost ?? '';
  }

  get defaultOptions() {
    return {
      timeZone: this.userConfig.currentTimeZone.identifier,
      hour12: true
    };
  }

  // ie. 07/24/22
  get shortDateOptions() {
    return {
      ...this.defaultOptions,
      ...{
        year: '2-digit',
        month: '2-digit',
        day: '2-digit'
      }
    };
  }

  // ie. 07/24/22, 11:14:38 AM
  get shortDateTimeOptions() {
    return {
      ...this.shortDateOptions,
      ...{
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }
    };
  }

  // ie. Jul 24, 2022
  get mediumDateOptions() {
    return {
      ...this.defaultOptions,
      ...{
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }
    };
  }

  // ie. Jul 24, 2022, 11:14:38 AM
  get mediumDateTimeOptions() {
    return {
      ...this.mediumDateOptions,
      ...{
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }
    };
  }

  // ie. Sunday, July 24, 2022
  get longDateOptions() {
    return {
      ...this.defaultOptions,
      ...{
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }
    };
  }

  // ie. Sunday, July 24, 2022 at 11:14:38 AM
  get longDateTimeOptions() {
    return {
      ...this.longDateOptions,
      ...{
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }
    };
  }

  formatDate(input, displayLength = DATE_LENGTH.MEDIUM) {
    const date = Number.isInteger(input) ? new Date(input) : input;
    if (this.#canUseRelativeTime(date)) {
      return this.formatRelativeTime(date);
    }

    switch (displayLength) {
      case DATE_LENGTH.SHORT:
        return this.#getDateTimeFormat(this.shortDateOptions).format(date);
      case DATE_LENGTH.MEDIUM:
        return this.#getDateTimeFormat(this.mediumDateOptions).format(date);
      case DATE_LENGTH.LONG:
        return this.#getDateTimeFormat(this.longDateOptions).format(date);
      default:
        return this.#getDateTimeFormat(this.longDateOptions).format(date);
    }
  }

  formatDateTime(input, displayLength = DATE_LENGTH.MEDIUM) {
    const date = Number.isInteger(input) ? new Date(input) : input;
    if (this.#canUseRelativeTime(date)) {
      return this.formatRelativeTime(date);
    }

    switch (displayLength) {
      case DATE_LENGTH.SHORT:
        return this.#getDateTimeFormat(this.shortDateTimeOptions).format(date);
      case DATE_LENGTH.MEDIUM:
        return this.#getDateTimeFormat(this.mediumDateTimeOptions).format(date);
      case DATE_LENGTH.LONG:
        return this.#getDateTimeFormat(this.longDateTimeOptions).format(date);
      default:
        return this.#getDateTimeFormat(this.longDateTimeOptions).format(date);
    }
  }

  formatToDefaultDateRange(startDate, endDate) {
    return [this.#formatToDefaultDate(startDate), this.#formatToDefaultDate(endDate)];
  }

  formatRelativeTime(input) {
    const date = Number.isInteger(input) ? new Date(input) : input;
    let duration = (date - new Date()) / 1000;
    const formatter = new Intl.RelativeTimeFormat(this.primaryLocale, { style: 'long' });

    for (const division of this.#DIVISIONS) {
      if (Math.abs(duration) < division.amount) {
        return formatter.format(Math.round(duration), division.name);
      }
      duration /= division.amount;
    }
  }

  formatNumber(number, options = {}) {
    return new Intl.NumberFormat(this.primaryLocale, options).format(number);
  }

  formatFloat(number, minDigits, maxDigits, isSignificant) {
    const minKey = isSignificant ? 'minimumSignificantDigits' : 'minimumFractionDigits';
    const maxKey = isSignificant ? 'maximumSignificantDigits' : 'maximumFractionDigits';
    const options = {
      [minKey]: Number(minDigits),
      [maxKey]: Number(maxDigits)
    };
    return this.formatNumber(number, options);
  }

  formatCurrency(number, currency = 'USD', args = {}) {
    return new Intl.NumberFormat(this.primaryLocale, { style: 'currency', currency, ...args }).format(number);
  }

  formatUnit(number, unit) {
    return new Intl.NumberFormat(this.primaryLocale, { style: 'unit', unit }).format(number);
  }

  formatList(list = []) {
    return new Intl.ListFormat(this.primaryLocale).format(list);
  }

  /**
   * Setup default language (en-us) and user's language on app setup
   */
  async setupIntl() {
    // Note: Always make en-us available as it is the fallback language
    if (!this.intl.locales.includes(FALLBACK_LOCALE)) {
      await this.setLanguage(FALLBACK_LOCALE);
    }

    if (this.sunsettedLanguages.includes(this.userLocaleCode)) {
      await this.sunsetUserLanguage();
    }

    if (this.userLocaleCode !== this.primaryLocale) {
      await this.setLanguage(this.userLocaleCode);
    }

    if (isEmpty(this.intl.locales)) {
      this.alerts.warning('The application did not load translation files correctly. Please refresh your browser.', {
        title: 'Translation Error',
        sticky: true,
        preventDuplicates: true
      });
    }
  }

  async getGeneratedAssetMap() {
    return await fetch('/assets/assetMap.json');
  }

  getTranslationPathFromAssetMap(assetMap, locale) {
    const translationPath = `translations/${locale}.json`;
    return `${this.assetHostPath}${assetMap.assets[translationPath]}`;
  }

  async fetchTranslationsFile(translationPath) {
    return await fetch(translationPath);
  }

  async setLanguage(locale) {
    try {
      if (isLazyLoad() && !this.intl.locales.includes(locale)) {
        const assetMap = await this.getGeneratedAssetMap();
        const translationPath = this.getTranslationPathFromAssetMap(assetMap, locale);
        const translations = await this.fetchTranslationsFile(translationPath);
        this.intl.addTranslations(locale, translations);
      }
      this.intl.setLocale([locale]);
      this.primaryLocale = locale;
      moment.locale(locale);
    } catch (error) {
      this.errors.log('Unable to set language', error);
    }
  }

  async setUserLanguage(locale) {
    try {
      this.auth.currentUserModel.set('language', locale);
      await this.auth.currentUserModel.save();
    } catch (error) {
      this.errors.log('Unable to setUserLanguage', error);
    }
  }

  async sunsetUserLanguage() {
    try {
      this.cache.remove(this.cacheKey);
      this.auth.currentUserModel.set('language', '');
      await this.auth.currentUserModel.save();
    } catch (error) {
      this.errors.log('Unable to sunset language', error);
    }
  }

  #DIVISIONS = [
    { amount: 60, name: 'seconds' },
    { amount: 60, name: 'minutes' },
    { amount: 24, name: 'hours' },
    { amount: 7, name: 'days' },
    { amount: 4.34524, name: 'weeks' },
    { amount: 12, name: 'months' },
    { amount: Number.POSITIVE_INFINITY, name: 'years' }
  ];

  #canUseRelativeTime(date) {
    const now = Date.now();
    const oneDay = 24 * 60 * 60 * 1000;
    return Math.abs(now - date) < oneDay;
  }

  #formatToDefaultDate(date) {
    const dateParts = new Intl.DateTimeFormat(this.primaryLocale, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      timeZone: this.userConfig.currentTimeZone.identifier
    }).formatToParts(date);

    const month = capitalize(dateParts.find((part) => part.type === 'month').value);
    const day = dateParts.find((part) => part.type === 'day').value;
    const year = dateParts.find((part) => part.type === 'year').value;

    // Flatpickr requires [long month day, year] e.g. Marzo 18, 2022
    return `${month} ${day}, ${year}`;
  }

  #getDateTimeFormat(options) {
    return new Intl.DateTimeFormat(this.primaryLocale, options);
  }
}
