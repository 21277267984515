/**
 * @module Services
 */

import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { S3Client } from '@aws-sdk/client-s3';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { Upload } from '@aws-sdk/lib-storage';
import Service, { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import RSVP from 'rsvp';

import config from 'later/config/environment';
import { convert } from 'later/utils/time-format';
import uuid from 'shared/utils/uuid';

/**
 * This service uploads media files into AWS S3
 *
 * @class MediaUploadService
 * @extends Service
 */
export default class MediaUploadService extends Service {
  @service auth;
  @service errors;
  @service laterConfig;
  @service localStorageManager;

  /**
   * The maximum size of an image a user can upload in bytes
   *
   * @property maxImageSize
   */
  get maxImageSize() {
    return this.auth.currentAccount?.maxImageFileSize || this.auth.currentGroup?.account?.get('maxImageFileSize');
  }

  /**
   * The maximum size of an image a user can upload in MB
   *
   * @property maxImageSizeMB
   */
  get maxImageSizeMB() {
    const bytesInKB = 1024;
    const KBInMB = 1024;
    return this.maxImageSize / KBInMB / bytesInKB;
  }

  /**
   * The maximum size of an image a user can upload in bytes
   *
   * @property maxImageSize
   */
  get maxVideoSize() {
    return this.auth.currentAccount?.maxVideoFileSize || this.auth.currentGroup?.account?.get('maxVideoFileSize');
  }

  /**
   * Generates a unique processing key for a file to be used within AWS.
   *
   *  @method generateProcessingKey
   *  @param {File} file
   *  @param {boolean | undefined} isVideo
   */
  generateProcessingKey(file, isVideo) {
    const prefix = `uploads-${uuid()}`;
    if (isVideo) {
      return prefix + '.mp4';
    }
    if (file.name && file.name.split('.').length > 1) {
      const parts = file.name.split('.');
      const extension = parts[parts.length - 1];

      //Note: handle case where file has bad extension
      if (['jpg', 'jpeg', 'gif', 'bmp', 'png', 'tiff', 'webp', 'tif'].includes(extension.toLowerCase())) {
        return prefix + '.' + extension;
      }
      return prefix + '.jpg';
    }
    return prefix + '.jpg';
  }

  /**
   * Uses AWS SDK V3 modular packages to setup S3 client with credentials.
   * Upload is handled by aws-sdk/lib-storage in order to track httpUploadProgress
   *
   * @method uploadToS3
   * @returns {Upload}
   */
  uploadToS3 = dropTask(async (file, processingBucket, processingKey, httpUploadCallback) => {
    const offset = await this._getOffset.perform();
    let lastPercent = 0;

    const options = {
      apiVersion: 'latest',
      correctClockSkew: true,
      credentials: fromCognitoIdentityPool({
        client: new CognitoIdentityClient({
          region: 'us-east-1'
        }),
        identityPoolId: this.laterConfig.cognitoIdentityPoolId,
      }),
      maxRetries: 3,
      region: 'us-east-1',
      systemClockOffset: offset,
      useAccelerateEndpoint: true
    };

    const target = {
      Body: Blob.prototype.stream ? file : this.#safariStream(file, httpUploadCallback),
      Bucket: processingBucket,
      ContentType: file.type,
      Key: processingKey
    };

    const upload = new Upload({
      client: new S3Client(options),
      params: target
    });

    if (Blob.prototype.stream && httpUploadCallback) {
      upload.on('httpUploadProgress', ({ loaded, total }) => {
        const progress = loaded / total;

        //cuts down on console output for full-story reasons, should max at 10 -iMack
        if (progress - lastPercent > 0.1) {
          lastPercent = progress;
        }
        httpUploadCallback(progress);
      });
    }

    return await upload.done();
  });

  _fetchOffset = dropTask(async () => {
    let offset = 0;
    const url = '/time';
    const maxSkewInMinutes = convert.minutes(5).toMilliseconds(); //skew < 5 min
    const isIncorrectSystemTime = this.localStorageManager.getItem('is_incorrect_system_time');
    const response = await fetch(url, {}, { method: 'GET', raw: true });
    if (response.headers.get('date')) {
      const correctTime = new Date(response.headers.get('date')).getTime();
      const offsetWithinBounds = Math.abs(offset) < maxSkewInMinutes;
      const newOffset = correctTime - new Date().getTime();
      if (isIncorrectSystemTime && offsetWithinBounds) {
        offset = newOffset;
        this.localStorageManager.removeItem('is_incorrect_system_time');
      }
    }
    return new RSVP.Promise((resolve, reject) => {
      resolve(offset), reject();
    });
  });

  _getOffset = dropTask(async () => {
    if (this.localStorageManager.getItem('is_incorrect_system_time')) {
      try {
        return await this._fetchOffset.perform();
      } catch (error) {
        this.errors.log(error, 'Could not get server time');
      }
    }
  });

  /**
   * Polyfill for Safari versions less than 14.1 that
   * do not support Blob.stream()
   *
   * https://github.com/aws/aws-sdk-js-v3/issues/2145
   *
   * @param {Blob} blob file to process
   * @param {Function} [callback]
   * @returns {ReadableStream}
   */
  #safariStream(blob, httpUploadCallback) {
    let position = 0;

    return new ReadableStream({
      async pull(controller) {
        const chunk = blob.slice(
          position,
          Math.min(blob.size, position + Math.max(controller.desiredSize, 1024 * 1024 * 5))
        );
        const buffer = await new Response(chunk).arrayBuffer();
        const uint8array = new Uint8Array(buffer);
        const bytesRead = uint8array.byteLength;

        position += bytesRead;
        controller.enqueue(uint8array);

        if (httpUploadCallback) {
          const progress = position / blob.size;
          httpUploadCallback(progress);
        }

        if (position >= blob.size) {
          controller.close();
        }
      }
    });
  }
}
