/* global Dropbox:readable */
import Service, { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import NProgress from 'nprogress';

import config from 'later/config/environment';
import loadScript from 'later/utils/load-script';

import type IntlService from 'ember-intl/services/intl';
import type AlertsService from 'later/services/alerts';
import type ErrorsService from 'later/services/errors';
import type LaterConfigService from 'later/services/later-config';
import type MediaItemUploadService from 'later/services/media-item-upload';
import type { ChooserFile } from 'shared/types/dropbox';

export default class DropboxService extends Service {
  @service declare alerts: AlertsService;
  @service declare errors: ErrorsService;
  @service declare intl: IntlService;
  @service declare laterConfig: LaterConfigService;
  @service declare mediaItemUpload: MediaItemUploadService;

  progressFunction(event: ProgressEvent): void {
    if (event.lengthComputable) {
      const percentComplete = (event.loaded / event.total) * 100;
      NProgress.set(percentComplete);
    }
  }

  setXhrCallbacks(xhr: XMLHttpRequest, file: ChooserFile): void {
    xhr.onprogress = (event: ProgressEvent) => {
      this.progressFunction(event);
    };
    xhr.onreadystatechange = (event: Event) => {
      this.stateChange.perform(event, file);
    };
  }

  openDropbox = task(async () => {
    try {
      const url = 'https://www.dropbox.com/static/api/2/dropins.js';
      const setAppKey = (script?: HTMLScriptElement): void => {
        script?.setAttribute?.('data-app-key', this.laterConfig.dropboxAppKey);
      };

      await loadScript(url, { id: 'dropboxjs' }, setAppKey);
      if (this.laterConfig.dropboxAvailable && typeof Dropbox !== 'undefined') {
        Dropbox.choose({
          // Required. Called when a user selects an item in the Chooser.
          success: (files) => {
            for (let i = 0; i < files.length; i++) {
              const file = files[i];
              const mediaError = this.mediaItemUpload.getMediaError(file);
              if (mediaError) {
                mediaError.resolve.call(this, mediaError);
              } else {
                NProgress.start();
                const xhr = new XMLHttpRequest();
                xhr.open('GET', file.link, true);
                xhr.responseType = 'blob';
                this.setXhrCallbacks(xhr, file);
                xhr.send(undefined);
              }
            }
          },
          cancel() {
            // Optional. Called when the user closes the dialog without selecting a file and does not include any parameters.
          },

          // Optional. "preview" (default) is a preview link to the document for sharing,
          // "direct" is an expiring link to download the contents of the file.
          linkType: 'direct', // or "direct"

          // Optional. A value of false (default) limits selection to a single file, while
          // true enables multiple file selection.
          multiselect: true,

          // Optional. This is a list of file extensions. If specified, the user will
          // only be able to select files with these extensions. You may also specify
          // file types, such as "video" or "images" in the list.
          extensions: ['images', 'video']
        });
      } else {
        this.alerts.clear();
        this.alerts.warning(this.intl.t('alerts.media_items.new.dropbox_not_available.message'), {
          title: this.intl.t('alerts.media_items.new.dropbox_not_available.title')
        });
      }
    } catch (error) {
      this.errors.log(error);
    }
  });

  stateChange = task(async (event: Event, file: ChooserFile) => {
    try {
      const xhr = event.currentTarget as XMLHttpRequest;

      if (xhr !== null) {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            const blob = xhr.response;
            blob.name = file.name;
            await this.mediaItemUpload.uploadMediaItem(blob);
            NProgress.done();
          }
        }
      }
    } catch (error) {
      this.alerts.warning(this.intl.t('alerts.dropbox.download_failed.message'), {
        title: this.intl.t('alerts.dropbox.download_failed.title')
      });
    }
  });
}

declare module '@ember/service' {
  interface Registry {
    dropbox: DropboxService;
  }
}
