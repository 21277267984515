import Service from '@ember/service';
import { task } from 'ember-concurrency';

import { DEFAULT_PRODUCT_FETCH_LIMIT } from 'later/utils/constants';
import graphCall from 'later/utils/graph-call';

export default class FacebookGraphService extends Service {

  /**
   * List of post fields to be returned from fetch media requests
   */
  postFields = [
    'message',
    'created_time',
    'from',
    'picture',
    'full_picture',
    'permalink_url',
    'type',
    'source',
    'reactions'
  ];

  /**
   * List of comment fields to be returned from fetch comment requests
   */
  commentFields = ['message', 'from', 'created_time', 'parent', 'reactions'];

  fetchPage = task(async (token: string, pageId: string) => {
    const result = await graphCall(
      `/${encodeURIComponent(pageId)}`,
      { fields: 'name,business' },
      {
        method: 'get',
        token
      }
    );
    return result;
  });

  fetchProduct = task(async (token: string | undefined, productId: string, businessAccountToken: string) => {
    const fields = ['image_url', 'name'];
    const result = await graphCall(
      `/${productId}`,
      { fields: fields.join(',') },
      {
        method: 'get',
        token,
        backupToken: businessAccountToken
      }
    );

    return result;
  });

  fetchProductTagEligibility = task(
    async (instagramUserId: string, token: string | undefined, businessAccountToken: string) => {
      return await graphCall(
        `/${encodeURIComponent(instagramUserId)}`,
        { fields: 'shopping_product_tag_eligibility' },
        {
          method: 'get',
          token,
          backupToken: businessAccountToken
        }
      );
    }
  );

  fetchAvailableCatalogs = task(
    async (instagramUserId: string, token: string | undefined, businessAccountToken: string) => {
      const result = await graphCall(
        `/${encodeURIComponent(instagramUserId)}/available_catalogs`,
        {},
        {
          method: 'get',
          token,
          backupToken: businessAccountToken
        }
      );

      return result;
    }
  );

  fetchCatalogProducts = task(
    async ({
      instagramUserId,
      token,
      params,
      paginationUrl,
      businessAccountToken
    }: {
      instagramUserId: string;
      token?: string;
      params: Record<string, unknown>;
      paginationUrl?: string;
      businessAccountToken: string;
    }) => {
      const catalogProductsUrl = paginationUrl || `/${encodeURIComponent(instagramUserId)}/catalog_product_search`;
      const result = await graphCall(
        catalogProductsUrl,
        { ...params, limit: DEFAULT_PRODUCT_FETCH_LIMIT },
        {
          method: 'get',
          token,
          backupToken: businessAccountToken
        }
      );

      return result;
    }
  );
}

declare module '@ember/service' {
  interface Registry {
    'social/facebook-graph': FacebookGraphService;
  }
}
